<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="queryParams"
        ref="queryParams"
        label-width="100px"
      >
        <el-form-item label="项目名" prop="name">
          <el-input
            class="inline-input"
            v-model="queryParams.name"
            placeholder="请输入项目名称"
            @select="handleSelect"
            clearable
          ></el-input>
          <!--    @onkey.enter='lll' -->
        </el-form-item>

        <el-form-item style="float: left" label="BD" prop="bdName">
          <el-input
            type="text"
            v-model="queryParams.bdName"
            placeholder="请输入BD姓名"
            width="100"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item style="float: left" label="PM" prop="pmName">
          <el-input
            type="text"
            v-model="queryParams.pmName"
            placeholder="请输入PM姓名"
            width="100"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="项目状态" prop="businessStatus">
          <el-select
            v-model="queryParams.businessStatus"
            class="childWidth"
            filterable
            placeholder=""
            @visible-change="selectProjecStatus($event)"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in porjectStatusListOptions"
              :key="item.code"
              :label="item.description"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions">重置</el-button>
          <el-button
            type="primary"
            autofocus
            v-permission="['admin:project:query_list:category_2']"
            @click="queryProjectList(1)"
            >查询</el-button
          >
          <el-button
            type="primary"
            autofocus
            v-permission="['admin:project:add:category_2']"
            @click="openDialog('')"
            >新增</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->
      <el-table border style="width: 100%" :data="tableData">
        <!-- <el-table-column type="index" label="#" width="40"></el-table-column> -->
        <!-- <el-table-column label="项目编号" prop="id"></el-table-column> -->
        <el-table-column label="项目名称" prop="name"></el-table-column>
        <el-table-column
          label="公司名称"
          prop="principalName"
        ></el-table-column>
        <el-table-column label="一句话描述" prop="tag"></el-table-column>
        <el-table-column label="项目简介" prop="intro">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="项目简介"
              width="400"
              trigger="hover"
              :content="scope.row.intro"
            >
              <span slot="reference" class="beyondDisplay1">{{
                scope.row.intro
              }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              v-permission="['admin:project:find_detail:category_2']"
              type="primary"
              size="mini"
              @click="detailDialog(scope.row)"
              >详情</el-button
            >
            <el-popover
              trigger="hover"
              placement="top"
              width="260"
              :ref="scope.$index"
            >
              <el-button
                v-permission="['admin:project:edit:category_2']"
                type="primary"
                size="mini"
                @click="openDialog(scope.row)"
                >编辑</el-button
              >

              <el-button
                v-permission="['admin:project:delete:category_2']"
                type="primary"
                size="mini"
                @click="deleteProject(scope.row)"
                >删除</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="coypProjectCode(scope.row)"
                class="tag-read"
                >复制项目码</el-button
              >
              <el-button
                icon="el-icon-view"
                slot="reference"
                style="margin: 0 0 0 10px; font-size: 18px"
                size="small"
                type="text"
              ></el-button>
            </el-popover>
            <!-- <el-button
              v-permission="['admin:project:claim:category_2']"
              type="primary"
              size="mini"
              @click="claimProject(scope.row)"
              >认领</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
        <!-- 转移 -->
        <el-dialog title="转移" :visible.sync="dialogVisible" width="40%">
          <transfer
            v-if="dialogVisible"
            :transferObj="transferObj"
            :dialogVisible="dialogVisible"
            v-on:transferBtn="transferBtn"
          ></transfer>
        </el-dialog>
        <!-- 添加|修改项目 -->
        <!-- <el-dialog
          :title="this.dialogTitle"
          :close-on-click-modal="false"
          :visible.sync="dialogStatus"
          width="70%"
          style="padding: 0 0 100px 0px"
          v-if="dialogStatus"
        >
          <addProjects
            ref="addProjects"
            :msPd="msPd"
            v-if="dialogStatus"
          ></addProjects>
          <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm()">取 消</el-button>

            <el-button type="primary" @click="addProject()">确 定</el-button>
          </div>
        </el-dialog> -->
        <addProjects
          ref="addProjects"
          v-if="addProjectDialog"
          :type="addProjectType"
          :addProjectDialog="addProjectDialog"
          v-on:listenAddProjectClose="listenAddProjectClose"
          :projectInfo="projectInfo"
          :category="queryParams.category"
        ></addProjects>
        <el-dialog
          title="详情"
          :visible.sync="dialogVisibleEdit"
          v-if="dialogVisibleEdit"
          width="60%"
          class="editDialogs"
          :close-on-click-modal="false"
          :destroy-on-close="false"
          :lock-scroll="false"
        >
          <detailsDialog :msPd="msPd" :projectInfo="currRow"></detailsDialog>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisibleEdit = false">取 消</el-button> -->
            <el-button type="primary" @click="dialogVisibleEdit = false"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  queryProjectList,
  addProject,
  editProject,
  deleteProjectById,
  claimProjectById,
  findProjectDetail,
  selectProjecStatus,
  coypProjectCode,
} from "@/api/projectManage";
import { formatDate } from "@/common/date";
import transfer from "../components/projectSecondaryMarket/transfer";
import addProjects from "../components/projectSecondaryMarket/addProject";
import detailsDialog from "../components/projectSecondaryMarket/detailsDialog";
export default {
  name: "projectSecondaryMarket",
  data() {
    return {
      tableData: [],
      detailId: null,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        projectName: null,
        createUser: null,
        pageSize: 10,
        pageNum: 1,
        category: "2",
        type: "2",
        nodeId: null,
        workflowId: null,
      },
      msPd: {},
      transferObj: {},
      dialogTitle: "添加项目",
      dialogStatus: false,
      dialogVisible: false,
      dialogVisibleEdit: false,

      porjectStatusListOptions: null,
      addProjectDialog: false,
      projectInfo: null,
      currRow: {}
    };
  },
  components: {
    transfer,
    addProjects,
    detailsDialog,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 10:
          return "待签约";
          break;
        case 20:
          return "已签约";
          break;
        case 30:
          return "BP梳理中";
          break;
        case 40:
          return "推进中";
          break;
        case 50:
          return "尽调中";
          break;
        case 60:
          return "TS";
          break;
        case 70:
          return "SPA";
          break;
      }
    },
  },
  created() {},
  mounted() {
    this.queryProjectList(1, this.pageInfo.pageSize);
  },
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("activated2====");
    let nodeId = this.$route.query.nodeId;
    if (nodeId) {
      this.queryParams.nodeId = nodeId;
      this.queryParams.workflowId = this.$route.query.workflowId;
    }
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {
    console.log("deactivated2====");
    this.queryParams.nodeId = null;
    this.queryParams.workflowId = null;
    this.queryProjectList(1, this.pageInfo.pageSize);
  },
  methods: {
    doCopy(data) {
      var that = this;
      this.$copyText(data).then(
        function (e) {
          that.$message({
            type: "success",
            message: "项目码:" + e.text + " 复制成功!",
          });
          console.log(e);
        },
        function (e) {
          that.$message({ type: "success", message: "项目码复制失败!" });
          console.log(e);
        }
      );
    },
    coypProjectCode(data) {
      if (!data.code) {
        coypProjectCode(data.id).then((response) => {
          if (response.success) {
            this.queryProjectList(
              this.pageInfo.pageNum,
              this.pageInfo.pageSize
            );
            this.$forceUpdate();
            this.doCopy(response.data.code);
          } else {
            this.$message({
              type: "info",
              message: response.message,
            });
          }
        });
      } else {
        this.doCopy(data.code);
      }
    },
    listenAddProjectClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addProjectDialog = !isClose;
      if (type == "sure") {
        this.queryProjectList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    selectProjecStatus(cb) {
      if (cb) {
        selectProjecStatus({}).then((response) => {
          if (response.success) {
            this.porjectStatusListOptions = response.data;
          }
        });
      }
    },
    transferProject(row) {
      this.transferObj = row;
      this.dialogVisible = true;
    },
    transferBtn(val) {
      this.queryProjectList(1, this.pageInfo.pageSize);
      this.dialogVisible = val;
      console.log(val);
    },
    querySearch(queryString, cb) {
      //项目模糊查找
      var arrCompany = [];
      this.tableData.forEach((res) => {
        arrCompany.push({
          value: res.projectName,
          id: res.id,
        });
      });
      var results = queryString
        ? arrCompany.filter(this.createFilter(queryString))
        : arrCompany;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      //选择的项目
      this.queryParams.id = item.id;
      this.queryParams.projectName = item.value;
    },
    detailDialog(row) {
      //详情id
      findProjectDetail(row).then((res) => {
        if (res.success) {
          this.msPd = res.data;
        }
      });
      this.currRow = row;
      this.dialogVisibleEdit = true;
    },
    assessmentClick(row) {
      //评估
      this.$store.commit("setRouterType", "openWindow");
      const { href } = this.$router.resolve({
        name: `assessment`,
        query: {
          rows: row.id,
          name: row.projectName,
        },
      });
      window.open(href);
    },
    ProcessManagement(id, projectName) {
      //流程进展
      this.$router.push({
        path: "/processManagement",
        query: { id: id, name: projectName },
      });
    },
    resetConditions() {
      this.queryParams.projectName = null;
      this.queryParams.bdName = null;
      this.queryParams.pmName = null;
      this.queryParams.businessStatus = null;
      this.queryParams.value1 = [];
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
    },
    queryProjectList(num, pageSize) {
      //项目库列表
      this.queryParams.pageNum = num;
      this.queryParams.pageSize = pageSize;
      queryProjectList(this.queryParams).then((response) => {
        this.tableData = response.data;
        // console.log(this.tableData);
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },

    handleSizeChange(val) {
      console.log(val);
      this.queryProjectList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryProjectList(val, this.pageInfo.pageSize);
    },
    openDialog(val) {
      console.log(val);
      if (val == "") {
        this.addProjectType = "add";
        this.dialogTitle = "添加项目";
        this.msPd = val;
      } else {
        // console.log(val);
        this.addProjectType = "edit";
        this.dialogTitle = "编辑项目";
        this.projectInfo = val;
      }
      console.log(this.projectInfo);

      this.addProjectDialog = true;
    },

    addProject() {
      //添加|修改 请求数据
      let form = this.$refs.addProjects.params;
      form.category = this.queryParams.category;

      console.log(form);
      if (form.id) {
        editProject(form).then((response) => {
          if (response.success) {
            this.$message({ type: "success", message: "编辑成功!" });
            this.dialogStatus = false;
            this.queryProjectList(1, this.pageInfo.pageSize);
          }
        });
      } else {
        addProject(form).then((response) => {
          if (response.success) {
            this.$message({ type: "success", message: "添加成功!" });
            this.dialogStatus = false;
            this.queryProjectList(1, this.pageInfo.pageSize);
          }
        });
      }
    },
    resetForm() {
      //取消蒙层
      this.dialogStatus = false;
    },
    deleteProject(data) {
      this.$confirm("确认删除项目 [" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteProjectById(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "删除成功!" });
              this.dialogStatus = false;
              this.queryProjectList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    claimProject(data) {
      this.$confirm("确认认领项目 [" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          claimProjectById(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "认领成功!" });
              this.dialogStatus = false;
              this.queryProjectList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}
</style>
