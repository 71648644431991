<template>
  <el-dialog
    :title="title"
    :visible="addProjectDialog"
    @close="resetForm"
    width="70%"
    style="padding: 0 0 100px 0px"
    :show-overflow-tooltip="true"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    lock-scroll
  >
    <el-form
      :model="params"
      ref="addProject"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="项目名" prop="projectName">
        <el-input
          type="text"
          maxlength="32"
          show-word-limit
          v-model="params.projectName"
          width="100"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="公司名称"
        prop="companyName"
        style="position: relative"
      >
        <div style="display: flex">
          <el-input
            v-model="params.companyName"
            placeholder="请输入内容"
            @blur="enter"
            @keyup.enter.native="enter"
          ></el-input>
          <el-button @click="enter()" style="margin-left: 5px" type="primary"
            >工商查询</el-button
          >
        </div>

        <ul class="mkName" :style="{ display: activeClass }">
          <li
            v-for="item in restaurantsres"
            @click="compamyMh(item)"
            :key="item.id"
          >
            {{ item.value }}
          </li>
        </ul>
      </el-form-item>

      <el-form-item label="融资阶段" prop="rounds">
        <el-select
          v-model="params.rounds"
          clearable
          placeholder="请选择"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in tz"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流程" prop="projectWorkflowId">
        <el-select
          v-model="params.projectWorkflowId"
          clearable
          placeholder="请选择"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in technological"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目BD" prop="initialOwnerId">
        <el-select
          v-model="params.initialOwnerId"
          @visible-change="projectTeam2($event)"
          filterable
          placeholder="请选择项目BD"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目类型" prop="type">
        <el-radio
          v-model="params.type"
          v-for="(item, index) in typeList"
          :key="index"
          :label="item.lable"
          >{{ item.name }}</el-radio
        >
      </el-form-item>
      <el-form-item label="评级" prop="rating">
        <el-input
          type="text"
          show-word-limit
          v-model="params.rating"
          width="100"
        ></el-input>
      </el-form-item>
      <el-form-item label="评估模版" prop="evaluationTemplateId">
        <el-select
          filterable
          :reserve-keyword="false"
          v-model="params.evaluationTemplateId"
          placeholder="请选择"
          clearable
          @visible-change="selectEvaluationTemplate($event)"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in evaluationTemplateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="项目组成员"
        prop="roleCodeList"
        style="width: 100% !important"
        class="roleCodeList"
      >
        <span slot="label"><i>*</i>项目组成员</span>
        <div class="memberList">
          <el-table border style="width: 100%" :data="params.memberList">
            <el-table-column label="角色" prop="roleCodeList">
              <template slot-scope="scope">
                <el-select
                  filterable
                  :reserve-keyword="false"
                  v-model="scope.row.roleCodeList"
                  placeholder="请选择"
                  clearable
                  @visible-change="roleSelectList($event)"
                  multiple
                  no-data-text="加载中···"
                >
                  <el-option
                    v-for="item in roleSelectListOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="realName"></el-table-column>
            <el-table-column label="备注" prop="roleNames"></el-table-column>
          </el-table>

          <!-- <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="this.pageInfo.pageNum"
              :page-sizes="[5, 10, 20]"
              :page-size="this.pageInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.pageInfo.total"
            ></el-pagination>
          </div> -->
        </div>
        <!-- <el-collapse accordion @change="roleCodeListHandleChange">
        <el-collapse-item>
          <template slot="title"> </template>
        
        </el-collapse-item>
      </el-collapse> -->
      </el-form-item>
      <el-divider content-position="left">股权融资需求</el-divider>
      <el-form-item label="融资金额" prop="demandAmount">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="text"
          show-word-limit
          v-model.number="params.demandAmount"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出让比例(%)" prop="transferRatio">
        <el-input-number
          type="text"
          show-word-limit
          v-model.number="params.transferRatio"
          width="100"
          :min="0"
          :controls="false"
          :precision="2"
          style="text-align: left !important"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="投前估值" prop="preInvestmentValuation">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="text"
          show-word-limit
          v-model.number="params.preInvestmentValuation"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投后估值" prop="postInvestmentValuation">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="text"
          show-word-limit
          v-model="params.postInvestmentValuation"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="立项日期" prop="projectApprovalTime">
        <el-date-picker
          v-model="params.projectApprovalTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="项目简介/其他信息" prop="intro">
        <el-input
          type="textarea"
          v-model="params.intro"
          :autosize="{ minRows: 2 }"
          maxlength="600"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- <div class="memberList">
      <p v-for="(item, index) in params.uidList" :key="index">
        {{ item.realName }}
        <i class="el-icon-delete-solid" @click="delectMember(item, index)"></i>
      </p>
    </div> -->
      <el-divider content-position="left">联系方式</el-divider>
      <el-form-item label="联系人" prop="contactName">
        <el-input
          style="width: calc(100% - 100px) !important"
          type="text"
          show-word-limit
          v-model="params.contactName"
          width="60"
        ></el-input>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.contactGender"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in contactGenderList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactPhone"
          width="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人职位" prop="contactPosition">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactPosition"
          width="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="微信" prop="contactWechat">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactWechat"
          width="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="E-Mail" prop="contactMail">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactMail"
          width="60"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left" v-if="params.type == 2"
        >FA</el-divider
      >
      <el-form-item
        v-show="params.type == 2"
        label="签约日期"
        prop="faSigningTime"
      >
        <el-date-picker
          v-model="params.faSigningTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        v-show="params.type == 2"
        label="合作点数(%)"
        prop="faRatio"
      >
        <el-input-number
          type="number"
          show-word-limit
          v-model="params.faRatio"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        label="是否独家"
        prop="faExclusive"
        v-show="params.type == 2"
      >
        <el-radio
          v-model.number="params.faExclusive"
          v-for="(item, index) in faExclusiveList"
          :key="index"
          :label="item.lable"
          >{{ item.name }}</el-radio
        >
      </el-form-item>
      <el-divider content-position="left">其他</el-divider>

      <el-form-item label="截止日期" prop="faEndTime">
        <el-date-picker
          v-model="params.faEndTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="备注" prop="note">
        <el-input
          type="text"
          v-model="params.note"
          :autosize="{ minRows: 3 }"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>

      <el-button type="primary" @click="addProject()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ROUNDS } from "@/common/constants";
import {
  projectTeam,
  selectTypeProject,
  selectEvaluationTemplate,
  addProject,
  editProject,
  findProjectDetail,
} from "@/api/projectManage";
import { searchCompany } from "@/api/fundCompany";

import { roleSelectList } from "@/api/jurisdiction";
export default {
  name: "addProject",
  props: ["type", "addProjectDialog", "projectInfo", "category"],
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("不能为空"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("请输入正确的小数位数")); //小数点后两位
      } else {
        callback();
      }
    };
    return {
      title: "新增",
      loading: false,
      msPd: null,
      params: {
        projectWorkflowId: null,
        companyName: null,
        rounds: null,
        companyOriginId: null,
        projectName: null,
        rating: null,
        note: null,
        evaluationTemplateId: null,
        type: "2",
        currencyType: "CNY",
        projectApprovalTime: new Date(),
        contactGender: null,
        contactMail: null,
        contactName: null,
        contactPhone: null,
        contactPosition: null,
        contactWechat: null,
        demandAmount: undefined,
        faEndTime: null,
        faExclusive: "",
        faRatio: undefined,
        faSigningTime: null,
        intro: null,
        postInvestmentValuation: undefined,
        preInvestmentValuation: undefined,
        transferRatio: undefined,
        memberList: [],
      },
      options: [],
      searchName: null,
      tz: [],
      name: [],
      technological: [],
      evaluationTemplateList: [],
      arr: [],
      activeClass: "none",
      restaurantsres: [],
      restaurants: [],
      rules: {
        // projectName: [{ validator: checkProjectName, trigger: "blur" }],
        projectName: [
          { required: true, message: "请输入项目名", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入公司名", trigger: "blur" },
        ],
        rounds: [
          { required: false, message: "请选择融资阶段", trigger: "change" },
        ],
        projectWorkflowId: [
          { required: false, message: "请选择流程", trigger: "change" },
        ],
        type: [
          { required: false, message: "请选择项目类型", trigger: "change" },
        ],
        evaluationTemplateId: [
          { required: false, message: "请选择模版", trigger: "change" },
        ],
        roleCodeList: [
          { required: false, message: "请选择项目组成员", trigger: "change" },
        ],
        currencyType: [
          { required: false, message: "请选择币种", trigger: "change" },
        ],
        demandAmount: [
          { required: false, message: "不能为空" },
          { type: "number", message: "必须为数字值" },
        ],
        transferRatio: [
          { required: false, message: "不能为空" },
          { type: "number", message: "必须为数字值" },
        ],
        preInvestmentValuation: [
          { required: false, message: "不能为空" },
          { type: "number", message: "必须为数字值" },
        ],
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: isMobileNumber, trigger: "blur" },
        ],
        faSigningTime: [
          { required: false, message: "请选择日期", trigger: "change" },
        ],
        faRatio: [{ required: false, message: "不能为空", trigger: "blur" }],
        initialOwnerId: [
          { required: true, message: "请选择项目BD", trigger: "change" },
        ],
        intro: [{ required: true, message: "请输入项目简介", trigger: "blur" }],
        contactPosition: [
          { required: true, message: "请输入联系人职位", trigger: "blur" },
        ],
      },
      typeList: [
        {
          lable: 1,
          name: "直投",
        },
        {
          lable: 2,
          name: "FA",
        },
      ],
      faExclusiveList: [
        {
          lable: 1,
          name: "是",
        },
        {
          lable: 0,
          name: "否",
        },
      ],
      currencyTypeList: [
        {
          code: "CNY",
          name: "人民币",
        },
        {
          code: "USD",
          name: "美金",
        },
      ],
      contactGenderList: [
        {
          code: 0,
          name: "女士",
        },
        {
          code: 1,
          name: "先生",
        },
        {
          code: 2,
          name: "未知",
        },
      ],
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 5,
        pageCount: 1,
      },
      roleSelectListOptions: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      internalUseroptionsList: [],
    };
  },
  computed: {},
  watch: {
    msPd(val, i) {
      console.log(val);
      var that = this;
      if (val) {
        this.openDialog(val);
        this.selectEvaluationTemplate(true);
        this.queryAccounts();
        this.projectTeam2(true);
        this.roleSelectList(true);
        that.technological.forEach(function (item, index) {
          if (item.name == val.projectWorkflowName) {
            that.params.projectWorkflowName = item.name;
            that.params.projectWorkflowId = item.id;
          }
        });
      }
    },
    deep: true,
    "params.type": {
      handler(newName, oldName) {
        if (newName == 1) {
          this.rules.faSigningTime[0].required = false;
          this.rules.faRatio[0].required = false;
        } else if (newName == 2) {
          // this.rules.faSigningTime[0].required = true;
          // this.rules.faRatio[0].required = true;
        }
        console.log("obj.type changed");
      },
      immediate: true,
    },
  },
  created() {
    this.technologicalList();
  },
  mounted() {
    this.technologicalList();
    ROUNDS.forEach((val) => {
      this.tz.push({
        value: val,
        label: val,
      });
    });
    // this.queryAccounts();

    if (this.type == "add") {
      this.title = "添加项目";
      this.queryAccounts();
    } else {
      this.title = "编辑项目";
      this.findProjectDetail(this.projectInfo);
    }
    // this.params = {
    //   projectWorkflowId: "WF100000025",
    //   companyName: "上海蛋小二信息科技集团有限公司",
    //   rounds: "未知轮次",
    //   companyOriginId: '3407791610',
    //   projectName: "project12",
    //   rating: "6",
    //   note: null,
    //   type: 1,
    //   currencyType: "CNY",
    //   projectApprovalTime: new Date(),
    //   evaluationTemplateId: "ET1000001",
    //   contactGender: "1",
    //   contactMail: "18317105921",
    //   contactName: "小五",
    //   contactPhone: "18317105921",
    //   contactPosition: "前端",
    //   contactWechat: "18317105921",
    //   demandAmount: 122,
    //   faEndTime: "2020-12-31",
    //   faExclusive: 1,
    //   faRatio: "6.2",
    //   faSigningTime: "2020-12-23",
    //   intro: "项目简介",
    //   postInvestmentValuation: "434",
    //   preInvestmentValuation: "234",
    //   transferRatio: 10,
    //   memberList: [
    //     {
    //       userId: "u00000038",
    //       roleCodeList: ["项目BD", "R013"],
    //     },
    //   ],
    // };
    // this.comPanys()
  },
  methods: {
    projectTeam2(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    addProject() {
      //添加|修改 请求数据
      var that = this;
      var form = {
        ...this.params,
        category: this.category,
      };
      this.$refs["addProject"].validate((valid) => {
        // console.log("-------------valid");

        if (valid) {
          console.log("-------------valid" + valid);

          let alist = [];
          console.log(form.memberList);
          // return false;
          form.memberList.forEach(function (item, index) {
            if (
              item.hasOwnProperty("roleCodeList") &&
              item.roleCodeList instanceof Array &&
              item.roleCodeList.length > 0
            ) {
              console.log("alist");
              let one = {};
              one.roleCodeList = item.roleCodeList;
              one.userId = item.uid;
              alist.push(one);
              // return false;
            } else {
            }
          });
          if (alist.length == 0) {
            that.$message({ type: "warning", message: "请选择项目组成员!" });
            return false;
          } else {
            form.memberList = alist;
          }
          // console.log(alist);
          console.log(form);

          if (this.type == "edit") {
            editProject(form).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "编辑成功!" });
                this.$emit("listenAddProjectClose", {
                  type: "sure",
                  isClose: true,
                });
              }
            });
          } else if (this.type == "add") {
            addProject(form).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "添加成功!" });
                this.$emit("listenAddProjectClose", {
                  type: "sure",
                  isClose: true,
                });
              }
            });
          }
        } else {
          console.log("-------------error");
          console.log("alist");
          console.log(form.memberList);

          let alist = [];
          form.memberList.forEach(function (item, index) {
            if (item.roleCodeList.length > 0) {
              console.log("alist");
              let one = {};
              one.roleCodeList = item.roleCodeList;
              one.userId = item.uid;
              alist.push(one);
              // return false;
            } else {
            }
          });
          if (alist.length == 0) {
            that.$message({ type: "warning", message: "请选择项目组成员!" });
            return false;
          } else {
            form.memberList = alist;
          }
        }
      });
    },
    resetForm() {
      this.$emit("listenAddProjectClose", { type: "cancel", isClose: true });
    },
    findProjectDetail(val) {
      findProjectDetail(val).then((res) => {
        if (res.success) {
          this.msPd = res.data;
          // this.msPd.uidList = res.data.uidList.map(item => item.uid);
        }
      });
    },

    roleCodeListHandleChange(val) {
      console.log(val);
      if (val) {
        this.queryAccounts();
      }
    },
    roleSelectList(cb) {
      if (cb && this.roleSelectListOptions.length == 0) {
        roleSelectList().then((response) => {
          this.roleSelectListOptions = response.data;
        });
      }
    },
    queryAccounts() {
      let info = {
        uid: null,
        username: null,
        realName: null,
      };
      projectTeam(info).then((response) => {
        let memberList = response.data;
        if (this.msPd) {
          this.params.memberList.map(function (item, index) {
            memberList.map(function (item2, index2) {
              if (item.userId == item2.uid) {
                item2.roleCodeList = item.roleCodeList;
              } else {
                // item2.roleCodeList = [];
              }
            });
          });
          this.params.memberList = memberList;
        } else {
          this.params.memberList = memberList;
        }

        // this.params.memberList = response.data;
        // if (response.total != null) {
        //   this.pageInfo.pageNum = response.pageNum;
        //   this.pageInfo.pageSize = response.pageSize;
        //   this.pageInfo.total = response.total;
        //   this.pageInfo.pageCount = response.pageCount;
        // } else {
        //   this.pageInfo.total = 0;
        // }
      });
    },
    handleSizeChange(val) {
      this.queryAccounts(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryAccounts(val, this.pageInfo.pageSize);
    },
    enter() {
      this.comPanys();
    },
    openDialog(val) {
      if (val == "") {
        this.params = null;
      } else {
        this.params = val;
        // this.params.uidList = this.params.uidList.map((e) => {
        //   return { uid: e.uid, realName: e.realName };
        // });
        this.$forceUpdate();
      }
    },
    searchMember(v) {
      if (v.length > 0) {
        let data = { realName: v };
        projectTeam(data).then((res) => {
          //项目组成员列表
          if (res.success) {
            this.options = res.data;
          }
        });
      } else {
        this.options = [];
      }
    },
    //添加人员
    selectMember(e) {
      console.log(e);
      if (
        JSON.stringify(this.params.uidList).indexOf(
          JSON.stringify({ uid: e.uid, realName: e.realName })
        ) === -1
      ) {
        this.params.uidList.push({ uid: e.uid, realName: e.realName });
        // this.params.uidList.push(e.uid)
      } else {
        this.$message.info("您有添加过此用户，请勿重复添加");
      }
      this.options = [];
    },

    //删除人员
    delectMember(params, index) {
      this.params.uidList = this.params.uidList.filter((i) => i != params);
    },

    compamyMh(item) {
      //选择的公司
      console.log(item);

      this.params.companyOriginId = item.id;
      this.params.companyName = item.value;
      this.activeClass = "none";
    },

    comPanys() {
      // console.log(this.params.companyName);
      searchCompany(this.params.companyName).then((res) => {
        //查找公司
        let years = [];
        if (res.success) {
          res.data.forEach((item) => {
            years.push({
              value: item.name,
              id: item.id,
            });
          });
        }
        var mkASrr = [];
        for (var i = 0; i < years.length; i++) {
          if (years[i].value.indexOf(this.params.companyName) >= 0) {
            mkASrr.push(years[i]);
          }
        }
        this.restaurantsres = mkASrr;
        this.activeClass = "block";
        if (this.restaurantsres.length == 1) {
          this.params.companyOriginId = this.restaurantsres[0].id;
          this.params.companyName = this.restaurantsres[0].value;
          this.activeClass = "none";
        }
      });
    },
    selectEvaluationTemplate(cb) {
      if (cb) {
        selectEvaluationTemplate({}).then((response) => {
          if (response.success) {
            this.evaluationTemplateList = response.data;
          }
        });
      }
    },
    technologicalList() {
      var that = this;
      //流程列表
      selectTypeProject({}).then((res) => {
        if (res.success) {
          // console.log(res.data);
          let technological = res.data;
          this.technological = technological;
        }
      });
    },
    keyChange(item) {
      console.log(item);
    },
  },

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48% !important;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
.addDailog >>> .el-form-item {
  display: flex;
  width: 48%;
  // float: left;
}
.addDailog >>> .el-form-item__content {
  margin: 0 !important;
  width: 100% !important;
}
.addDailog >>> .el-select {
  width: 100% !important;
}
.addDailog >>> .el-autocomplete {
  width: 100% !important;
}
.addDailog >>> .el-input-number {
  width: 100% !important;
}
.addDailog >>> .el-textarea {
  width: 100% !important;
}
.mkName {
  list-style: none;
  position: absolute;
  width: 92%;
  top: 40px;
  left: 0;
  margin: 0;
  background: #fff;
  z-index: 99;
  border: 1px solid #ccc;
}
.mkName li {
  line-height: 35px;
}
.memberList {
  height: 20rem;
  overflow-y: scroll;
}
.memberList i {
  margin-left: 3px;
}
.memberList p {
  flex-shrink: 0;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
i {
  color: #f56c6c;
  margin-right: 4px;
}
</style>